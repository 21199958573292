<template>
    <master-panel>
        <template v-slot:body>
            <loading-spinner v-if="loading" line/>
            <v-row v-else>
                <v-col cols="12">
                    <title-container title="Editar Cuestionario"/>
                </v-col>
                <v-col cols="12" class="text-center">
                    <v-btn color="primary" class="normal-btn" small depressed @click="skip" :loading="loadingFinish">
                        Omitir
                    </v-btn>
                </v-col>
                <v-col cols="12" v-for="(question, i) in questionnaire" :key="i">
                    <div class="title-question">
                        Pregunta ({{ i + 1 }}):
                    </div>
                    <kimsa-text-field
                        @change="question.text = $event"
                        :value="question.text"
                        placeholder="Escriba una pregunta aquí..."
                        outlined dense
                    />
                    <div v-if="question.type === 'check'" class="mt-3">
                        <div class="title-question">
                            Opciones:
                        </div>
                        <div v-for="(option, j) in question.options" :key="j" class="mb-1">
                            <kimsa-text-field
                                @change="option.text = $event"
                                :value="option.text"
                                placeholder="Escriba una opción aquí..."
                                outlined dense
                                append-icon="mdi-delete"
                                @click:append="onDeleteOption(i, j, option.id)"
                            />
                        </div>
                        <div class="text-right mt-2">
                            <v-btn color="primary" class="normal-btn" x-small depressed
                                   @click="onAddOption(i, question.id)">
                                Agregar opción
                            </v-btn>
                        </div>
                    </div>
                    <div v-if="question.type === 'text' || question.type === 'textarea'" class="">
                        <small>(La respuesta sera un campo de texto)</small>
                    </div>
                    <div v-if="question.type === 'date'" class="">
                        <small>(La respuesta sera un campo para seleccionar fecha)</small>
                    </div>
                    <v-divider class="mt-3" v-if="questionnaire.length > i + 1"/>
                </v-col>
            </v-row>
        </template>
        <template v-slot:buttons>
            <v-btn color="secondary" class="normal-btn" small depressed @click="finish" :loading="loadingFinish">
                Guardar
            </v-btn>
        </template>
    </master-panel>
</template>

<script>
import httpService from "@/services/httpService";
import MasterPanel from "@/components/layouts/MasterPanel";
import LoadingSpinner from "@/components/common/LoadingSpinner";
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import KimsaTextareaField from "@/components/common/inputs/KimsaTextareaField";
import KimsaDatePicker from "@/components/common/inputs/KimsaDatePicker";
import TitleContainer from "@/components/elements/TitleContainer";

export default {
    name: "DoctorQuestionnaire",
    components: {TitleContainer, KimsaDatePicker, KimsaTextareaField, KimsaTextField, LoadingSpinner, MasterPanel},
    data() {
        return {
            loading: false,
            questionnaire: [],
            loadingFinish: false,
            optionsDeleted: [],
        }
    },
    async mounted() {
        const vm = this
        await vm.loadQuestionnaire()
    },
    methods: {
        async loadQuestionnaire() {
            const vm = this
            vm.loading = true
            let response = await httpService.get('doctor/questionnaire')
            // console.log('loadQuestionnaire', response)
            vm.questionnaire = response?.data?.questionnaire || []
            vm.loading = false
        },
        onDeleteOption(questionIndex, optionIndex, optionId) {
            const vm = this
            vm.questionnaire[questionIndex].options.splice(optionIndex, 1)
            if (optionId) vm.optionsDeleted.push(optionId)
        },
        onAddOption(questionIndex, questionId) {
            const vm = this
            vm.questionnaire[questionIndex].options.push({question_id: questionId, text: ''})
        },
        async finish() {
            const vm = this
            vm.loadingFinish = true

            let questionnaire = vm.questionnaire
                .filter(question => !!question.text)
                .map(question => ({
                    id: question.id,
                    text: question.text,
                    options: question.options
                        .filter(option => !!option.text)
                        .map(option => ({
                            id: option.id || null,
                            text: option.text,
                        }))
                }))

            // console.log('questionnaire', questionnaire);
            await httpService.post('doctor/questionnaire/update', {questionnaire: questionnaire, options_deleted: vm.optionsDeleted})

            vm.loadingFinish = false
            await vm.skip()
        },
        async skip() {
            const vm = this
            await httpService.post('doctor/finished')
            vm.goTo({name: 'doctor.signature'})
        }
    },
}
</script>

<style scoped>

.title-question {
    font-weight: bold;
}

.title-container {
    background-color: var(--v-secondary-base);
    border-radius: 0 12px 12px 0;
    padding: 16px 0;
    width: 85%;
}

.title-text {
    color: #FFFFFF;
}

</style>